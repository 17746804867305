// src/components/Product.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { formatDateRange } from '../utils/dateUtils';

const statusConfig = {
    available: { bg: 'success', label: '在庫あり' },
    rented: { bg: 'warning', label: 'レンタル中' },
    soldOut: { bg: 'danger', label: '売り切れ' },
};

const Product = React.memo(({
    type, // 'favorite', 'gallery', 'order', 'shop', 'standard'
    product,
    onDelete,
    isAdmin,
}) => {
    const handleDeleteClick = (e) => {
        e.stopPropagation();
        if (onDelete) onDelete(product.id);
    };

    const getCaption = () => {
        switch (type) {
            case 'order':
                return product.rentalPeriod ? 'レンタル' : '購入';
            case 'shop':
                if (product.serviceOption === 'rental') return <Badge bg='warning'>レンタル</Badge>;
                if (product.serviceOption === 'purchase') return <Badge bg='info'>購入</Badge>;
                return product.category?.name || null;
            default:
                return product.category?.name;
        }
    };

    const getPrice = () => {
        switch (type) {
            case 'order':
                if (product.startDate && product.endDate) {
                    return formatDateRange(product.startDate, product.endDate);
                }
                if (product.useSubscription) {
                    return 'サブスク利用';
                }
                return null;
            default:
                return `¥${product.price}`;
        }
    };

    const showStatus = () => {
        if (type === 'shop') return false;
        if (type === 'gallery') return false;
        if (type === 'order') return false;
        if (type === 'standard') return false;
        return !!product.status;
    };

    const statusBadge = () => {
        if (!showStatus()) return null;
        const config = statusConfig[product.status] || { bg: 'secondary', label: 'Unknown' };
        return <Badge bg={config.bg}>{config.label}</Badge>;
    };

    const imageLink = `/product/${product.id}/edit`;

    return (
        <li className={`product rounded ${type === 'gallery' ? 'product--gallery' : ''}`} key={product.id}>
            <Link className='product__img-wrapper' to={imageLink}>
                <img src={product.image} alt={product.name} loading='lazy' />
            </Link>
            <div className='product__body'>
                <b className='product__title'>{type === 'shop' && isAdmin ? product.shopName : product.name}</b>
                <div className='product__caption'>{getCaption()}</div>
                <div className='product__price-wrapper'>
                    <div className='product__price'>{getPrice()}</div>
                    {statusBadge()}
                </div>
            </div>
            {(type === 'favorite' || (type === 'shop' && (isAdmin || product.status === 'available' || product.status === 'draft'))) && onDelete && (
                <Button className='btn-sm product__delete-btn' onClick={handleDeleteClick}>
                    <FaTimes />
                </Button>
            )}
        </li>
    );
});

Product.propTypes = {
    type: PropTypes.oneOf(['favorite', 'gallery', 'order', 'shop', 'standard']).isRequired,
    product: PropTypes.shape({
        id: PropTypes.number.isRequired,
        image: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        category: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({ name: PropTypes.string }),
        ]),
        price: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        status: PropTypes.oneOf(['available', 'rented', 'soldOut']),
        shopName: PropTypes.string,
        serviceOption: PropTypes.oneOf(['rental', 'purchase']),
        rentalPeriod: PropTypes.string,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        useSubscription: PropTypes.bool,
    }).isRequired,
    onDelete: PropTypes.func,
    isAdmin: PropTypes.bool,
};

Product.defaultProps = {
    onDelete: null,
    isAdmin: false,
};

export default Product;