import { useMemo, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, Button, Container, Row, Col, Pagination, Modal, Form } from 'react-bootstrap';
import { FaEdit, FaTrash, FaBluetoothB } from 'react-icons/fa';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import Tabs from '../../components/Tabs';
import { toast } from 'react-toastify';
import {
    useGetMyShopProductsQuery,
    useCreateProductMutation,
    useDeleteProductMutation,
} from '../../slices/productsApiSlice';
// import {
//     requestAndStoreBluetoothDeviceName,
//     getStoredBluetoothDeviceName,
//     getStoredSerialPortIdentifier,
//     connectToBLEDevice,
//     chooseAndConnectToBLEDevice,
//     setImage,
//     printImage
// } from '../../utils/phomemoPrinter.ts';
import Product from '../../components/Product.jsx';

const ShopProductListScreen = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const initialPageNumber = Number(queryParams.get('pageNumber')) || 1;
    const initialStatus = queryParams.get('status') || 'available';

    const [selectedStatus, setSelectedStatus] = useState(initialStatus);
    const [page, setPage] = useState(initialPageNumber);

    // const { data: products, isLoading, error, refetch } = useGetMyShopProductsQuery();
    const [createProduct, { isLoading: loadingCreate }] = useCreateProductMutation();
    const [deleteProduct, { isLoading: loadingDelete }] = useDeleteProductMutation();
    const [showModal, setShowModal] = useState(false); // モーダルの表示状態
    const [productCount, setProductCount] = useState(1); // デフォルトで1件

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => {
        setShowModal(false);
        setProductCount(1);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);

    // クエリパラメータの変更に応じてページとステータスを更新
    useEffect(() => {
        const params = new URLSearchParams();
        if (selectedStatus) params.append('status', selectedStatus);
        if (page !== 1) params.append('pageNumber', page);
        navigate(`?${params.toString()}`);
    }, [selectedStatus, page, navigate]);

    // useGetMyShopProductsQuery にクエリパラメータを渡す
    const { data, isLoading, error, refetch } = useGetMyShopProductsQuery({
        status: selectedStatus,
        pageNumber: page,
    });

    const meta = data?.meta;

    // const [serialPortIdentifier, setSerialPortIdentifier] = useState('');
    // const [bluetoothDeviceName, setBluetoothDeviceName] = useState('');

    // useEffect(() => {
    //     const storedSerialPortIdentifier = getStoredSerialPortIdentifier();
    //     if (storedSerialPortIdentifier) {
    //         setSerialPortIdentifier(storedSerialPortIdentifier);
    //     }
    //     const storedBluetoothDeviceName = getStoredBluetoothDeviceName();
    //     if (storedBluetoothDeviceName) {
    //         setBluetoothDeviceName(storedBluetoothDeviceName);
    //     }
    // }, []);

    const deleteHandler = async (id) => {
        if (window.confirm('本当にこの商品を削除しますか？')) {
            try {
                await deleteProduct(id).unwrap();
                toast.success('商品の削除に成功しました');
                refetch();
            } catch (err) {
                toast.error(err?.data?.message || err.error);
            }
        }
    };

    // const handleChangeBluetoothDevice = async () => {
    //     try {
    //         const newDeviceName = await requestAndStoreBluetoothDeviceName();
    //         setBluetoothDeviceName(newDeviceName);
    //         toast.success('Bluetooth登録プリンターが更新されました');
    //     } catch (error) {
    //         console.error('Error changing Bluetooth device:', error);
    //         toast.error('Bluetooth登録プリンターの更新に失敗しました');
    //     }
    // };

    // const handleDeleteBluetoothDevice = async () => {
    //     try {
    //         setBluetoothDeviceName('');
    //         localStorage.removeItem('bluetoothDeviceName');
    //         toast.success('Bluetooth登録プリンターが削除されました');
    //     } catch (error) {
    //         console.error('Error deleting Bluetooth device:', error);
    //         toast.error('Bluetooth登録プリンターの削除に失敗しました');
    //     }
    // };

    // async function ensureBluetoothConnection() {
    //     try {
    //         if (!bluetoothDeviceName) {
    //             const userConfirmed = window.confirm('Bluetoothデバイス名が未設定です。新しいデバイスを選択しますか？');
    //             if (!userConfirmed) return false;
        
    //             const newDeviceName = await chooseAndConnectToBLEDevice();
    //             if (!newDeviceName) return false;
        
    //             setBluetoothDeviceName(newDeviceName);
    //             localStorage.setItem('bluetoothDeviceName', newDeviceName);
    //         } else {
    //             const connected = await connectToBLEDevice(bluetoothDeviceName);
    //             if (!connected) return false;
    //         }
    //         return true;
    //     } catch (error) {
    //         console.error('Error ensuring Bluetooth connection:', error);
    //         console.error('エラーメッセージ:', error.message);
    //         return false;
    //     }
    // }

    const createProductHandler = async () => {
        try {
            // Bluetoothに接続する
            // const connected = await ensureBluetoothConnection();
            // if (!connected) {
            //     toast.error('Bluetooth登録プリンターへの接続に失敗しました');
            //     return;
            // }
                // toast.success('商品が作成されました。QRコードを印刷します');
    
                // QRコードを生成して印刷
                // const qrCodeUrl = `https://www.furupura.jp/product/${newProductId}`;
                // const imageSet = await setImage(qrCodeUrl);
                // if (!imageSet) {
                //     toast.error('QRコードの設定に失敗しました');
                //     return;
                // }
                // const printed = await printImage('bluetooth');
                // if (!printed) {
                //     toast.error('QRコードの印刷に失敗しました');
                // } else {
                //     toast.success('QRコードを印刷しました。商品タグに貼り付けてください');
                // }
                const response = await createProduct({ count: productCount }).unwrap();
                toast.success(response.message);
                refetch();

                setSelectedStatus('draft');
                handleCloseModal(); 
        } catch (err) {
            toast.error('商品の作成に失敗しました。もう一度お試しください。何度も失敗する場合は、サポートにお問い合わせください。');
            console.log('Error creating product:', err);
        }
    };
    
    const tabsHandler = (value) => {
        setSelectedStatus(value);
    };

    const statusTabs = useMemo(() => [
        { label: '在庫', value: 'available', ariaSelected: selectedStatus === 'available' },
        { label: '下書き(非公開)', value: 'draft', ariaSelected: selectedStatus === 'draft' },
        { label: 'レンタル中', value: 'rented', ariaSelected: selectedStatus === 'rented' },
        { label: '売り切れ', value: 'sold', ariaSelected: selectedStatus === 'sold' },
    ], [selectedStatus]);

    // const filteredProducts = useMemo(() => {
    //     return products ? products.filter(product => product.status === selectedStatus) : [];
    // }, [products, selectedStatus]);

    return (<>
        <Container className='pb-2'>
            <h1 className='mt-4'>商品管理ページ</h1>
    
            {loadingCreate && <Loader />}
            {loadingDelete && <Loader />}


            <h3 className='alert alert-success mt-4'>すべての商品</h3>

            {/* <Card className='mt-2 d-inline-block w-auto'>
                <Card.Body>
                    <Row>
                        <Col md='auto'>
                            <FaBluetoothB className='mb-1'/> <strong>Bluetoothプリンター：</strong> <br />
                            {bluetoothDeviceName || '未設定'}
                        </Col>
                        <Col className='d-flex align-items-center'>
                            <Button className='btn-sm me-1' variant="secondary" onClick={handleChangeBluetoothDevice}>
                                <FaEdit color='white' />
                            </Button>
                            <Button className='btn-sm' variant="danger" onClick={handleDeleteBluetoothDevice}>
                                <FaTrash color='white' />
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card> */}

            <Row className='mt-3'>
                <Col>
                    <Button className='btn-sm' onClick={handleOpenModal}>
                        <FaEdit /> 商品を追加
                    </Button>
                </Col>
            </Row>

            <Tabs
                tabs={statusTabs}
                onClick={(value) => {
                    tabsHandler(value);
                    setPage(1); // ステータス変更時にページをリセット
                }}
            />
    
            {isLoading ? <Loader /> : error ? <Message variant='danger'>{error.data?.message}</Message> : (
                <>
                    <ul className='product-list product-list--shop pb-3'>
                        {data.products.map((product) => (
                            <Product
                                key={product.id} // key を追加
                                type="shop"
                                product={product}
                                onDelete={deleteHandler}
                                isAdmin={false}
                            />
                        ))}
                    </ul>

                    {/* ページネーションボタンの追加 */}
                    {meta.pages > 1 && (
                        <Pagination className='justify-content-center mt-5'>
                            <Pagination.First onClick={() => setPage(1)} disabled={page === 1} />
                            <Pagination.Prev onClick={() => setPage(prev => Math.max(prev - 1, 1))} disabled={page === 1} />

                            {/* ページ番号の表示（必要に応じて表示数を調整） */}
                            {Array.from({ length: meta.pages }, (_, idx) => idx + 1).map(pageNumber => (
                                <Pagination.Item
                                    key={pageNumber}
                                    active={pageNumber === page}
                                    onClick={() => setPage(pageNumber)}
                                >
                                    {pageNumber}
                                </Pagination.Item>
                            ))}

                            <Pagination.Next onClick={() => setPage(prev => Math.min(prev + 1, meta.pages))} disabled={page === meta.pages} />
                            <Pagination.Last onClick={() => setPage(meta.pages)} disabled={page === meta.pages} />
                        </Pagination>
                    )}
                </>
            )}
        </Container>
        <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>何着作成しますか？</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="productCount">
                    <Form.Label>商品数</Form.Label>
                    <Form.Control
                        type="number"
                        value={productCount}
                        min={1}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (value === '') {
                                setProductCount(''); // 空の場合は空文字をセット
                            } else {
                                const parsedValue = parseInt(value, 10); // 整数として解析
                                if (!isNaN(parsedValue) && parsedValue >= 1) {
                                    setProductCount(parsedValue); // 1以上の値のみセット
                                }
                            }
                        }}
                        onBlur={() => {
                            // フォーカスが外れたときに、値が空なら最小値をセット
                            if (!productCount) {
                                setProductCount(1);
                            }
                        }}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    キャンセル
                </Button>
                <Button
                    variant="primary"
                    onClick={createProductHandler}
                    disabled={loadingCreate}
                >
                    {loadingCreate ? '作成中...' : '作成'}
                </Button>
            </Modal.Footer>
        </Modal>
    </>
    )
    
}

export default ShopProductListScreen;