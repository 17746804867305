// src/utils/webp.js

import { wrap } from 'comlink';

// Web Workerの初期化
const worker = new Worker(new URL('../workers/webpWorker.js', import.meta.url), {
  type: 'module',
});
const api = wrap(worker);

/**
 * 画像ファイルをWebPにエンコードする関数
 *
 * @param {File} file - アップロードされた画像ファイル
 * @returns {Promise<File | null>} - エンコードされたWebPファイル
 */
export const encodeImageToWebPFile = async (file, max_size = 524288) => {
  try {
    const imageData = await fileToImageData(file);
    if (!imageData) return null;

    const { data, width, height } = imageData;
    const encoded = await api.encode(data, width, height, max_size);

    if (!encoded) {
        console.error('エンコード結果が空です。');
        throw new Error('WebPエンコードに失敗しました。');
    }
  
    const encodedSize = encoded.length; // Uint8Arrayのサイズを取得
    console.log(`エンコードされたデータのサイズ: ${encodedSize} バイト`);
    console.log(`指定された最大サイズ: ${max_size} バイト`);

    if (encodedSize > max_size) {
        console.warn('エンコード後のデータが最大サイズを超えています。');
    } else {
        console.log('エンコード後のデータは最大サイズ内です。');
    
    }
    if (!isWebP(encoded)) {
        console.error('エンコード後のデータがWebP形式ではありません。');
        throw new Error('WebPエンコードに失敗しました。サポートにお問い合わせください。');
    }
    // Uint8Array -> Blob -> Fileに変換
    const webpBlob = new Blob([encoded], { type: 'image/webp' });
    const webpFile = new File([webpBlob], `${file.name.split('.')[0]}.webp`, {
      type: 'image/webp',
    });

    console.log('WebPファイル作成成功:', webpFile, webpFile.size);
    return webpFile;
  } catch (error) {
    console.error('WebPエンコード中にエラーが発生しました:', error);
    return null;
  }
};

/**
 * ファイルを読み込み、ImageDataに変換する純粋関数
 *
 * @param {File} file - 画像ファイル
 * @returns {Promise<{data: Uint8Array, width: number, height: number} | null>}
 */
const fileToImageData = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = () => {
      const img = new Image();
      img.onload = () => {
        // Canvasを使用して画像をRGBAデータに変換
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        const imageData = ctx.getImageData(0, 0, img.width, img.height);
        resolve({
          data: imageData.data,
          width: img.width,
          height: img.height,
        });
      };
      img.src = reader.result;
    };

    reader.readAsDataURL(file);
  });
};






/**
 * ファイルがWebP形式かを確認する関数
 *
 * @param {Uint8Array} data - バイナリデータ
 * @returns {boolean} - WebP形式の場合はtrue、それ以外はfalse
 */
const isWebP = (data) => {
    // WebPファイルのMagic Bytes
    const riffHeader = [0x52, 0x49, 0x46, 0x46]; // "RIFF"
    const webpHeader = [0x57, 0x45, 0x42, 0x50]; // "WEBP"
  
    // ヘッダーの長さを確認
    if (data.length < 12) return false;
  
    // RIFFとWEBPヘッダーを確認
    const isRiff = riffHeader.every((byte, index) => data[index] === byte);
    const isWebP = webpHeader.every(
      (byte, index) => data[index + 8] === byte
    );
  
    return isRiff && isWebP;
  };
  